<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <!-- Striped Rows -->
      <div class="col-xxl-9">
        <div class="card mt-xxl-n5">
          <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Id</th>

                    <th scope="col">Nom</th>

                    <th scope="col">Prenoms</th>

                    <th scope="col">Specialité</th>

                    <th scope="col">statut</th>

                    <th scope="col">Action</th>

                </tr>

            </thead>

            <tbody>

                <tr v-for="teacher in teachers" :key="teacher.id">

                    <th scope="row">{{ teacher.id }}</th>

                    <td>{{ teacher.nom }}</td>

                    <td>{{ teacher.prenom }}</td>

                    <td>{{ teacher.specialite }}</td>

                    <td v-if="teacher.statuts == 'valider'"><span class="badge bg-success">{{ teacher.statuts }}</span>
                    </td>
      
                    <td><span class="badge bg-danger">{{ teacher.statuts }}</span></td>
                    <td>
                        <div class="hstack gap-3 flex-wrap">
                            <a href="javascript:void(0);" class="link-success fs-15"><i class="ri-edit-2-line"></i></a>
                            <a href="javascript:void(0);" class="link-danger fs-15"><i class="ri-delete-bin-line"></i></a>
                        </div>
                    </td>

                </tr>

            </tbody>
          </table>
        </div>
      </div>
    </Layout>
</template>
  
<script>
  
  import Layout from "@/layouts/main.vue";
  import PageHeader from "@/components/page-header";
  
  export default {
    setup() { },
  
    data() {
      return {
        teachers: [],
        title: "Liste",
        items: [
          {
            text: "Professeurs",
            href: "/",
          },
          {
            text: "Tout",
            active: true,
          },
        ],
      };
    },
    components: {
      Layout,
      PageHeader,
    },
    mounted() {
      this.getTeachers()
    },
  
    methods: {
      getTeachers() {
      const self = this;
      this.$store.dispatch('getRequest', { route: 'api/user/professeur ', data: this.data })
        .then(
          function (response) {
            self.teachers = response.data.searchedUsers;
            console.log(self.teachers);
          },
          function (error) {
           console.log(error);
          }
        )
    },
    },
  };
</script>
  
  